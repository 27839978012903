







































import { Vue, Component } from "vue-property-decorator";
import axios, { APIResponse } from "@/plugins/axios";
import AlertModule, { AlertType } from "@/store/modules/alert";

@Component
export default class UnsubscribeNewsletter extends Vue {
    private isLoading = true;
    private isError = false;

    private async created() {
        try {
            this.isLoading = true;
            await this.unsubScribeFromNewsletter(this.$route.params.hash);
            this.isLoading = false;
        } catch (err) {
            this.isError = true;

            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    private async unsubScribeFromNewsletter(hash: string): Promise<true> {
        const response = (
            await axios.get<APIResponse<null>>(
                "/newsletter/unsubscribe/" + hash
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknowError");
        }
        return true;
    }
}
